@import url("https://fonts.googleapis.com/css2?family=Mukta&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Literata:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  scroll-behavior: smooth;
  font-family: "Georgia", serif;
}

body {
  overflow-x: hidden;
}

.fontFamily,
.destination,
.navoi-map-text,
.day-para,
.up-class,
.our-clients,
.experents,
.subscribe,
.interactive-map,
.quiz-text {
  font-family: "Georgia", serif;
}

.leaflet-container {
  position: relative;
  width: 100%;
  height: 90vh;
  z-index: 19;
}
.leaflet-touch .leaflet-bar {
  position: absolute;
  top: 40vh;
}

.letter-text {
  color: rgba(0, 0, 0, 0.51);
}

.markerClusterSmall {
  border-radius: 20px;
  font-size: 0.6vw;
  color: #fff;
  font-weight: 900;
  font-family: "Open Sans", sans-serif;
  text-transform: uppercase;
}
.markerClusterMedium {
  border-radius: 40px;
  font-size: 0.6vw;
  color: #fff;
  font-weight: 900;
  font-family: Open Sans, sans-serif;
  text-transform: uppercase;
}
.markerClusterLarge {
  border-radius: 70px;
  font-size: 0.6vw;
  color: #fff;
  font-weight: 900;
  font-family: Open Sans, sans-serif;
  text-transform: uppercase;
}
.markerClusterLargeXL {
  border-radius: 70px;
  font-size: 0.6vw;
  color: #fff;
  font-weight: 900;
  font-family: Open Sans, sans-serif;
  text-transform: uppercase;
}
.markerClusterLabel {
  display: flex;
  justify-content: center;
  align-items: center;
}

.destination {
  font-family: "Mukta", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 32px;
  line-height: 53px;
  color: #9e9e9e;
}

.sub-menu:hover.sub-image {
  animation-name: op;
  animation-duration: 2s;
  animation-direction: reverse;
}
@keyframes op {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.place:hover .circle {
  opacity: 1;
}

.swiper {
  width: 100%;
  padding-top: 50px;
  padding-bottom: 50px;
}

.swiper-slide {
  background-position: center;
  background-size: cover;
  width: 300px;
  height: 300px;
}
.mySwiper2 {
  height: 100%;
  width: 100%;
}

/* .mySwiper {
  height: 20%;
  box-sizing: border-box;
  padding: 10px 0;
} */

.mySwiper .swiper-slide {
  width: 25%;
  height: 100%;
  /* opacity: 0.4; */
}

.mySwiper .swiper-slide-thumb-active {
  opacity: 1;
}
.swiper-slide img {
  /* display: block; */
  width: 100%;
}

@layer base {
  /* Navbar */
  .navbar {
    @apply z-[100] fixed top-0 w-full transition-all ease-linear duration-300;
  }

  .static-navbar {
    @apply bg-[#00000080];
  }

  .fixed-navbar {
    @apply bg-[#FFFFFF];
  }

  .fixed-navbar .navbar-link {
    @apply text-black;
  }

  .navbar-link {
    @apply text-white hover:text-[#42a469] transition-all ease-in-out duration-200 cursor-pointer;
  }

  .active-link {
    @apply text-color-page-text hover:text-color-page-text transition-all ease-in-out duration-200 cursor-pointer;
  }

  /*  Navbar */
  .header {
    @apply w-full  relative;
  }

  /* .header-bg {
    @apply w-full h-full absolute top-0 left-0  -z-10;
  } */

  /* .header__left-side {
    @apply h-full flex flex-col justify-center gap-[2rem] w-[50%] absolute top-0 left-0  pl-[5%] ;
  } */

  .header__left-side {
    z-index: 40;
    width: 50%;
    height: 100%;
    position: absolute;
    clip-path: polygon(0 0, 55% 0, 100% 100%, 0% 100%);
    mix-blend-mode: multiply;
    background: linear-gradient(
      224.25deg,
      rgba(53, 109, 149, 0.6) 16.1%,
      rgba(44, 169, 64, 0.6) 105.77%
    );
  }
  .header-title {
    font-family: "Georgia", serif;
  }

  /*  best places */
  .best-places-card {
    @apply relative md:h-[50vh] sm:h-[45vh] h-[40vh] text-white cursor-pointer overflow-hidden;
  }

  .place__card-image {
    @apply w-full h-[50vh] absolute transition-all duration-700 ease-in-out transform bg-center bg-cover object-cover;
  }

  .best-places-card:hover .place__card-image {
    @apply scale-125;
  }

  .card-overlay {
    @apply absolute bottom-5 left-6;
  }

  .card-overlay-title {
    @apply text-xl;
  }

  .best-places-title {
    @apply mt-10 text-5xl text-color-main-blue;
  }

  /* Suggesstions trip  */
  .play__quiz {
    @apply w-full text-white;
  }
  .play__quiz-body {
    @apply px-32 pt-24;
  }
  .play__quiz-title {
    @apply text-6xl;
  }
  .play__quiz-description {
    @apply py-6 text-[2rem] mb-2;
  }
  .play__quiz-btn {
    @apply xl:text-2xl text-xl font-bold bg-green-700 hover:bg-green-600 transition ease-in-out delay-150 py-2 px-12 mb-20;
  }
  .play__quiz-cards {
    @apply flex justify-between items-center lg:gap-8 gap-4;
  }
  .play__quiz-card {
    @apply w-[30%] overflow-hidden transform transition-all ease-in-out duration-500;
  }
  .play__quiz-card:hover {
    @apply translate-y-6;
  }
  .play__quiz-card-btn {
    @apply w-full xl:px-2 px-1 hover:text-slate-500 transition-all ease-in-out duration-500 xl:text-xl lg:text-base text-[0.8rem] text-[#1A4D65];
  }
  .play__quiz-bg {
    @apply w-full 2xl:h-[90%] xl:h-[85%] h-[80%] absolute -z-20;
  }
  .play__quiz-bg-opacity {
    @apply w-full 2xl:h-[90%%] xl:h-[85%] h-[80%] absolute bg-black bg-opacity-60 -z-10;
  }

  /* Navoi map  */
  .navoi-map {
    @apply relative w-full z-10 mt-4;
  }

  .navoi__map-title {
    @apply absolute z-20 p-4 bg-black bg-opacity-50 text-center font-normal text-4xl text-white;
  }

  .content {
    @apply w-full relative;
  }
  .content-event-title {
    @apply text-6xl;
  }
  .content__img {
    @apply w-full top-0 fixed -z-10;
  }
  .content__head {
    @apply bg-white mt-[60vh];
  }
  .content__head-container {
    @apply w-3/4 mx-auto;
  }
  .map__hero {
    @apply w-full h-[90vh] relative;
  }

  .map__hero-bg {
    @apply w-full h-full;
  }
  .container {
    @apply w-3/4 mx-auto;
  }
  .map__hero-content {
    @apply absolute w-[60%] top-1/2 left-40 text-white;
  }
  .map__hero-title {
    @apply lg:text-5xl md:text-4xl sm:text-3xl text-2xl uppercase;
  }
  .map__hero-title span {
    @apply text-[#E9BF06];
  }
  .place__about {
    @apply relative;
  }
  .place__about-head {
    @apply w-[90%] mx-auto py-6;
  }
  .place__about-head-title {
    @apply text-[3rem] text-green-500;
  }
  .place__about-img {
    @apply absolute lg:right-40 md:right-24 lg:-bottom-40 md:-bottom-32 md:visible invisible lg:w-[20rem] lg:h-[20rem] md:w-[16rem]  md:h-[16rem] sm:w-[15rem] sm:h-[15rem] w-[10rem] h-[10rem]  rounded-full;
  }
  .place__head-body {
    @apply w-[90%] mx-auto flex justify-between items-center;
  }
  .place__head-body-img {
    @apply md:w-1/2 w-full;
  }
  .place__about-text {
    @apply md:w-2/5 w-full md:mt-1 mt-4 font-normal leading-8 text-justify;
  }
  .place__history {
    @apply h-[90vh] bg-green-600 my-10 relative;
  }
  .place__history-container {
    @apply container mx-auto px-4 py-9 text-white;
  }
  .place__history-title {
    @apply mb-9 text-end text-5xl;
  }
  .place__history-body {
    @apply w-[90%] flex justify-between items-center pt-9 absolute right-0;
  }
  .place__history-hr {
    @apply border;
  }
  .place__history-text {
    @apply w-2/5 text-justify leading-8 text-xl;
  }
  .place__history-end {
    @apply h-[90vh] relative my-10 relative mb-20;
  }
  .place__history-end-container {
    @apply container mx-auto py-9 absolute left-0;
  }
  .place__history-end-text {
    @apply w-2/5 text-justify leading-8 text-xl;
  }
  .place__history-end-image {
    @apply h-[90vh];
  }
  .place__history-end-body {
    @apply w-full flex justify-between items-center;
  }
  .explore__place-container {
    @apply container mx-auto py-9;
  }
  .explore__place-title {
    @apply text-4xl text-[#1A4D65] font-sans font-bold;
  }
  .map__slider-btn {
    @apply w-full flex justify-between items-center py-2 px-3 bg-green-500 text-white;
  }
  .map__slider-slide {
    @apply w-[20%];
  }

  /* Newsletter  */
  .newsletter {
    @apply w-full my-16  bg-[#589E6C];
  }
  .newsletter__container {
    @apply flex justify-between items-center relative pt-[3.3rem] pb-[5.225rem];
  }
  .newsletter-title {
    @apply text-[36px] text-white font-mukta font-semibold;
  }
  .newsletter-description {
    @apply text-[24px] text-[#ADD6B8] mt-[30px] mb-[64px] w-4/5 font-mukta;
  }
  .newsletter-input {
    @apply px-4 py-2 w-3/5 focus:outline-green-600 font-mukta;
  }
  .newsletter__btn {
    @apply mx-2 font-mukta font-bold py-2 px-6 bg-color-light-yellow text-white;
  }
  .newsletter__email-letter {
    @apply absolute top-32 right-96 z-10;
  }
  .newsletter__img {
    @apply absolute -top-12 right-2;
  }

  /* Testimonials  */

  .testimonials {
    @apply w-full px-20 my-4;
  }
  .testimonials-container {
    @apply flex justify-between items-center relative py-8;
  }
  .testimonials__map {
    @apply w-full;
  }
  .testimonials__image {
    @apply w-full pr-32;
  }
  .testimonials__slider {
    @apply w-1/2;
  }
  .testimonials__slider-title {
    @apply text-5xl text-[#304578];
  }
  .testimonials__sldier-account {
    @apply flex items-center my-6;
  }
  .testimonials__sldier-account-name {
    @apply ml-4;
  }
  .testimonials__slider-body-text {
    @apply leading-6;
  }

  /* Holiday  */
  .holiday__header,
  .events__header,
  .art-header {
    @apply relative;
  }
  .navruz_image {
    @apply h-4/5;
  }
  .holiday__header-text {
    @apply absolute text-white top-1/3 left-[40%];
  }
  .holiday-title,
  .holiday-description,
  .events__body-title {
    @apply text-center;
  }
  .holiday-title {
    @apply text-8xl;
  }

  .holiday-description {
    @apply text-2xl;
  }

  .holiday__body,
  .events__body {
    @apply px-16;
  }

  .holiday__body-description,
  .events__description {
    @apply px-40 py-8 text-center text-xl;
  }
  .holiday__body-title {
    @apply text-5xl my-6;
  }
  .holiday__body-history {
    @apply mb-16;
  }
  .holiday__body-history-section {
    @apply flex justify-between items-center;
  }
  .holiday__body-history-text {
    @apply w-2/5 leading-8 text-justify mr-20 text-xl;
  }
  .holiday__body-history-subtext {
    @apply my-8 leading-8 text-justify text-xl;
  }
  .holiday__body-history-dishes--text {
    @apply w-1/2 pr-14;
  }
  .holiday__body-history-dishes--description {
    @apply text-xl text-justify leading-10;
  }
  .holiday__body-history-dishes {
    @apply flex;
  }
  .holiday__body-history-dishes-title {
    @apply text-5xl;
  }
  .holiday__body-history-dishes-subtitle {
    @apply text-3xl py-4;
  }
  .holiday__body-history-send {
    @apply mr-2;
  }
  .holiday__body-history-right {
    @apply h-[520px];
  }
  .holiday__btn {
    @apply w-full text-2xl text-white bg-color-light-yellow py-2 mt-4;
  }

  .holiday__line {
    @apply w-1/3 mx-auto border-2 border-color-light-yellow;
  }
  .holiday__line-margin {
    @apply mb-12;
  }
  .color-main-blue {
    @apply text-color-main-blue;
  }
  .color-light-yellow {
    @apply text-color-light-yellow;
  }

  .holiday__gallery-title,
  .holiday__event-slider-title,
  .holiday__gallery-title {
    @apply text-5xl my-6;
  }
  .holiday__events-container,
  .events__slider-container {
    @apply flex justify-around items-center;
  }
  .event-card {
    @apply relative cursor-pointer overflow-hidden;
  }
  .event__image {
    @apply transition-all duration-700 ease-in-out transform;
  }
  .event-card:hover .event__image {
    @apply scale-125;
  }
  .date-time {
    @apply absolute z-10 right-0 top-[15%] px-8 bg-color-light-yellow rounded-l-2xl font-extrabold text-center;
  }
  .do_you-know {
    @apply px-52 text-lg;
  }
  .do_you-know-body {
    @apply flex justify-center items-center;
  }
  .do_you-know-text {
    @apply w-3/4 mx-14 my-12 text-center text-lg;
  }
  .share {
    @apply fixed flex flex-col top-1/3 right-0 z-30;
  }
  .share__icon {
    @apply p-4 bg-color-light-yellow transition-all ease-in-out duration-500 hover:bg-white hover:text-color-light-yellow cursor-pointer text-4xl text-white;
  }

  /* Events  */

  .events__slider-title,
  .events__slider-description,
  .search__events-title,
  .search__events-description {
    @apply px-16 my-4 text-left;
  }
  .events__slider-title,
  .search__events-title {
    @apply text-5xl;
  }
  .events__slider-description,
  .search__events-description {
    @apply text-2xl mb-12;
  }
  .events__slider-container {
    @apply my-6 mx-12;
  }
  .events__header-title {
    @apply absolute top-1/3 text-6xl px-12 py-4 bg-white opacity-75;
  }
  .events__body-title {
    @apply mt-8 text-2xl font-bold;
  }

  .events__form {
    @apply px-16 my-6 text-right;
  }
  .events__form-input {
    @apply flex justify-between items-center text-left;
  }
  .input__block {
    @apply relative;
  }
  .events__input {
    @apply w-[25rem] my-6 px-4 py-2 border-2 bg-transparent border-slate-700 focus:outline-yellow-400;
  }
  .events__btn {
    @apply px-10 py-2 my-4 bg-color-main-green text-xl hover:bg-green-600 transition-all ease-in-out duration-500 text-white rounded-2xl;
  }
  .events__province,
  .events__date {
    @apply relative;
  }
  .event__icon {
    @apply absolute top-[37%] right-2 text-2xl cursor-pointer;
  }
  .arthero-header-slider {
    @apply mx-6 flex justify-between items-center;
  }
  .arthero-header-slider__width {
    @apply w-1/2;
  }

  .bakhshi__section,
  .hero-section {
    @apply flex justify-between items-center lg:gap-10 md:gap-1 mt-8;
  }
  .kashta__images {
    @apply w-full;
  }
  .meseum__hero-section-text,
  .hero-section-text {
    @apply text-lg leading-8 text-justify font-mukta md:px-0 px-4;
  }
  .meseum__container-title {
    @apply absolute lg:top-1/2 top-1/3 left-[12%] text-white text-6xl md:text-5xl text-4xl capitalize;
  }
  .meseum__hero-image {
    @apply md:w-[80%] w-full md:px-0 px-4 cursor-pointer transition-all ease-in-out duration-500 transform hover:scale-110;
  }
  .kashta__hero-image {
    @apply md:w-1/3 w-3/4 md:h-[250px] h-[200px] cursor-pointer transition-all ease-in-out duration-500 transform hover:scale-110;
  }
  .card__title {
    @apply my-4;
  }
  .meseum__cards-title {
    @apply text-5xl;
  }

  .meseum__hr {
    @apply w-1/2 mx-auto my-8 px-8 border border-[#F9CD0B];
  }
  .bakhshi-gallery {
    @apply flex justify-between items-center py-12;
  }
  .bakhshi-gallery__image {
    @apply md:w-1/3 w-1/2 cursor-pointer transition-all ease-in-out duration-500 transform hover:-translate-y-4;
  }
  .bakhshi__cards-title {
    @apply px-24 text-5xl;
  }
  .bakhshi-subtext {
    @apply text-justify leading-8 text-lg;
  }
  .bakhshi__section {
    @apply px-24;
  }
  .bakhshi-header,
  .folkhero-header,
  .arthero-header {
    @apply relative md:h-[90vh] h-[50vh];
  }
  .bakhshi-header__image,
  .folk-header__image,
  .arthero-header__img {
    @apply absolute w-full h-full top-0 left-0;
  }
  .bakhshi-header-text,
  .folk-header-text,
  .header-text {
    @apply absolute top-1/2 left-[12%] z-10 text-white;
  }
  .bakhshi-header-title,
  .folk-header-title,
  .header-title {
    @apply lg:text-6xl md:text-5xl text-xl mb-6 capitalize;
  }
  .bakhshi-header-description,
  .folk-header-description,
  .arthero-header__description {
    @apply w-4/6 lg:text-2xl md:text-xl text-lg;
  }

  .card-title-container {
    @apply w-full px-24;
  }
  .folk-table {
    @apply w-full;
  }
  .folk-table td {
    @apply p-2;
  }
  .folk-table td img {
    @apply cursor-pointer transition-all ease-in-out duration-500 transform hover:scale-110 hover:-rotate-12;
  }

  .card-title-container {
    @apply my-6;
  }

  .header__image {
    @apply absolute w-full h-full top-0 left-0;
  }

  .popup-content {
    @apply block overflow-y-auto;
  }

  .popup-content img {
    @apply w-full h-full object-cover object-center rounded-md cursor-pointer;
  }

  .popup-content p {
    @apply my-2;
  }
  .practical-icon {
    @apply transition-all ease-in-out duration-500;
  }
  .practical-categories:hover .practical-icon {
    @apply -translate-y-4;
  }
  .practical-title {
    @apply transition-all ease-in-out duration-500;
  }
  .practical-categories:hover .practical-title {
    @apply text-[#589E6C];
  }
}

/*Gastronomy*/
.hero {
  @apply relative h-[400px] md:h-[508px];
}

.gastronomy__hero {
  background: url("assets/Images/gastronomy/bg-image.png") no-repeat center
    center;
  background-size: cover;
}

.triangle {
  position: relative;
}

.triangle::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  border-top: 0 solid transparent;
  border-right: 0 solid transparent;
  border-bottom: 72px solid #f9cd0b;
  border-left: 72px solid transparent;
  transform: rotate(180deg);
}

.triangle::after {
  content: "";
  position: absolute;
  bottom: 0;
  right: 0;
  border-top: 0 solid transparent;
  border-right: 0 solid transparent;
  border-bottom: 72px solid #f9cd0b;
  border-left: 72px solid transparent;
}

.main-swiper {
  height: 100%;
}

.main-swiper .slider {
  height: 100%;
}

.main-swiper .carousel {
  height: 100%;
}

.main-swiper .slider-wrapper {
  height: 100% !important;
}
